<template>
  <div>
    <v-container class="d-print-none">
      <page-title class="mb-5" title="月報表" />
      <div class="mb-5">
        <v-select
          dense
          outlined
          hide-details
          :items="dateOptions"
          label="選擇月份"
          v-model="month"
        ></v-select>
      </div>
      <!-- <div class="mb-5">
        <v-select dense outlined hide-details :items="optionsPositionRole" label="選擇客戶" v-model="selected"
          @change="onChange"></v-select>
      </div> -->
    </v-container>

    <div class="mb-5 d-print-none">
      <tabs v-model="tabData" :options="options" />
    </div>

    <v-container id="element-to-print">
      <v-row
        class="tools-area pa-1 d-print-none"
        v-if="data && data.length > 0"
      >
        <v-col cols="12" md="6" class="py-0"></v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-toolbar
            v-if="isShowExportToolbar"
            flat
            dense
            slot="list-data-title"
            class="d-flex justify-end float-right d-print-none"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" small class="mx-2">
                  {{ "action.export" | t }}
                </v-btn>
              </template>

              <v-list dense class="d-print-none">
                <v-list-item dense @click="exportData('PDF')">
                  <v-list-item-title>
                    {{ "action.picking_list.export.pdf" | t }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item dense @click="exportData('EXCEL')">
                  <v-list-item-title>
                    {{ "action.picking_list.export.excel" | t }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="mt-0" v-if="data">
        <v-col ref="table" class="mb-10" cols="12">
          <report-form
            :headers="tableOptions.header"
            :contents="tableOptions.content"
          ></report-form>
        </v-col>

        <template v-if="showCharts">
          <template v-for="(data, index) in pieChartsData">
            <v-col
              :ref="`pieChart-${index}`"
              class="px-4"
              cols="12"
              md="4"
              :key="'pie' + index"
              v-if="currTabObj.id == 2 || (currTabObj.id == 1 && !selected)"
            >
              <pie-chart :chartData="data"></pie-chart>
            </v-col>
          </template>

          <template v-for="(item, index) in lineChartsData">
            <v-col
              :ref="`lineChart-${index}`"
              class="px-4"
              cols="12"
              md="4"
              :key="'line' + index"
            >
              <template v-if="lineChartsDataLoaded">
                <line-chart
                  :chartData="item"
                  :options="lineChartOptions"
                ></line-chart>
              </template>
              <template v-else>
                <v-skeleton-loader
                  v-bind="attrs"
                  type="image, image, image"
                ></v-skeleton-loader>
              </template>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-container>

    <!-- 分頁 -->
    <v-pagination
      v-if="pager"
      v-model="page"
      :length="pager.pages"
      :total-visible="6"
    ></v-pagination>
    <div v-if="pager" style="height: 60px"></div>
  </div>
</template>

<script>
import reportMixins from "@/mixins/report.js";
import qs from "qs";
import tabs from "@/modules/base/components/tabs.vue";
import reportForm from "@/components/reportForm/index.vue";

import PieChart from "@/components/charts/PieChart.vue";
import LineChart from "@/components/charts/lineChart.vue";

export default {
  mixins: [reportMixins],
  components: {
    tabs,
    reportForm,
    PieChart,
    LineChart,
  },
  data: () => ({
    options: [
      {
        id: 1,
        text: "客戶排行",
        key: "store",
        mainDataApiClass: "stores",
        mainDataCommonKey: "store",
        showCharts: true,
        showExportToolbar: true,
      },
      {
        id: 2,
        text: "商品排行",
        key: "product",
        mainDataApiClass: "products",
        mainDataCommonKey: "product",
        showCharts: true,
        showExportToolbar: true,
      },
      {
        id: 3,
        text: "管理效率",
        key: "efficiency",
        mainDataApiClass: "efficiency",
        mainDataCommonKey: "store",
        showCharts: false,
        showExportToolbar: false,
      },
    ],
    attrs: {
      class: "hv-100",
    },
  }),
  computed: {
    tableOptions() {
      let tableHeaderKey = "TableHeaders";
      let TableContentKey = "TableContents";

      let key = this.currTabObj.key;
      return {
        header: this[key + tableHeaderKey],
        content: this[key + TableContentKey],
      };
    },
    storeTableHeaders() {
      return [
        { text: "排名", cols: 2, class: "text-center" },
        { text: "客戶", class: "text-center" },
        { text: "訂單", cols: 2, class: "text-center" },
        { text: "總金額", class: "text-center" },
      ];
    },
    storeTableContents() {
      return this.data.map((item) => {
        return [
          { text: item.rank, cols: 2, class: "text-center" },
          { text: item.name, class: "text-center" },
          { text: `${item.count}筆`, cols: 2, class: "text-center" },
          { text: item.amount, class: "text-center", type: "price" },
        ];
      });
    },
    productTableHeaders() {
      return [
        { text: "排名", cols: 2, class: "text-center" },
        { text: "商品", class: "text-center" },
        { text: "數量", cols: 2, class: "text-center" },
        { text: "總金額", class: "text-center" },
      ];
    },
    productTableContents() {
      return this.data.map((item) => {
        return [
          { text: item.rank, cols: 2, class: "text-center" },
          { text: item.name, class: "text-center" },
          { text: item.count, cols: 2, class: "text-center" },
          { text: item.amount, class: "text-center", type: "price" },
        ];
      });
    },
    efficiencyTableHeaders() {
      return [
        { text: "商店名稱", class: "text-center align-content-center" },
        {
          text: "平均訂單處理時間",
          cols: 3,
          class: "text-center align-content-center",
        },
        {
          text: "訂單貨運時間",
          cols: 3,
          class: "text-center align-content-center",
        },
      ];
    },
    efficiencyTableContents() {
      return this.data.map((item) => {
        return [
          { text: item.name, class: "text-center align-content-center" },
          {
            text: `${item.average_order_processing_time}天`,
            cols: 3,
            class: "text-center align-content-center",
          },
          {
            text: `${item.order_shipping_time}天`,
            cols: 3,
            class: "text-center align-content-center",
          },
        ];
      });
    },
    query() {
      return {
        filter: qs.stringify(this.queryFilter, { arrayFormat: "indices" }),
      };
    },
    queryFilter() {
      return {
        created_at: this.month,
      };
    },
    tokenRoleId() {
      return this.$store.getters[`member/providerId`];
    },
    apiParams() {
      return {
        provider: this.tokenRoleId,
        params: this.params,
        mainDataApiClass: this.currTabObj.mainDataApiClass,
      };
    },
    indexApi() {
      return this.$api.collection.reportApi.providerGetMainData;
    },
    params() {
      const dateRange = this.searchDateRange;

      const params = {
        pager: false,
        start_date: dateRange.start_date,
        end_date: dateRange.end_date,
      };
      if (this.selected) {
        params.filter = {
          store_id: this.selected,
        };
      }
      return params;
    },
    paramsForCoData() {
      return {
        // filter: {
        //   status: 'cooperation',
        // }
      };
    },
  },
  mounted() {
    this.$root.gaLogEvent("供應商_報表頁");
  },
  watch: {
    query: {
      deep: true,
      async handler(newValue, oldValue) {
        if (!this.init) return;
        if (this.$eagleLodash.isEqual(newValue, oldValue)) return;
        this.$router.push({ query: this.query });
        await this.getData();
      },
    },
  },
  async created() {
    this.init = false;
    this.parseQuery();
    await this.getData();
    await this.getCoData();
    this.init = true;
  },
  methods: {
    getDataCallback(data) {
      const mainDataCommonKey = this.currTabObj.mainDataCommonKey;
      const currTabId = this.currTabObj.id;
      const sortData = (a, b) => b.amount - a.amount;

      const formattedData = data.sort(sortData).map((item, index) => {
        let newObj = {
          id: item[mainDataCommonKey + "_id"],
          rank: index + 1,
          name: mainDataCommonKey == "store" ? item.store_name : item.name,
        };
        switch (currTabId) {
          case 1:
            newObj = {
              count_number: Math.round(Number(item.count)),
              count: item.count,
              amount: Math.round(item.amount),
              ...newObj,
            };
            break;
          case 2:
            newObj = {
              count_number: Math.round(
                Number(
                  this.$helper.reverseShippingCount(
                    item.shipping,
                    item.shipping_unit,
                    2
                  )
                )
              ),
              count: `${this.$helper.reverseShippingCount(
                item.shipping,
                item.shipping_unit,
                2
              )} ${item.shipping_unit}`,
              amount: Math.round(item.amount),
              ...newObj,
            };
            break;
          case 3:
            newObj = {
              average_order_processing_time: item.average_order_processing_time,
              order_shipping_time: item.order_shipping_time,
              ...newObj,
            };
            break;
        }

        return newObj;
      });
      this.data = formattedData;
      if (this.showCharts) {
        this.setPieChartsData();
        this.getReportByDate();
      }
    },
  },
};
</script>
